* {
  margin: 0;
  padding: 0;
 }

body {
  background-color: black;
}

header {
  align-items: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: row;
  font-size: calc(10px + 2vmin);
  justify-content: space-around;
  min-height: 10vh;
}

.site-logo {
  animation: alternante 10s linear infinite;
  border-radius: 20px;
  filter: invert(100%) sepia(25%) saturate(18%) hue-rotate(88deg) brightness(108%) contrast(102%);
  height: 80px;
  width: 100px;
}

.header-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.header-image {
  border-radius: 10px;
  margin: 5px;
  width: 100px;
}

.channels-container {
  align-items: center;
  background-color: black;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 3px;
}

.channel-card {
  align-items: center;
  background-color: rgb(90, 90, 90);
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  height: 110px;
  justify-content: space-around;
  margin: 2px;
  padding: 5px;
  text-align: center;
  width: 110px;
}

.channel-card:hover {
  background-color: rgb(150, 150, 150);
  box-shadow:  0 0 0 2px red;
}

.channel-card img {
  border-radius: 10px;
  max-height: 60px;
  margin: 5px;
  width: 60px;
}

.player-container {
  align-items: center;
  background-color: #17191d;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100vh;
}

.inputcontainer {
  color: white;
  display: flex;
  margin: 10px;
}

.inputcontainer * {
  margin: 5px;
}

.streams-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 450px;
  height: 50px;
}

.streams-container button {
  /* width: 125px; */
  /* height: 55px; */
  background-color: rgb(238, 174, 174);
  padding: 10px;
}

.streams-container button:hover {
  background-color: rgb(240, 109, 109);
}

.streams-p {
  margin-top: 10px;
}

.h3-desc {
  margin: 0px 3%;
}

.p-description {
  margin: 10px 3%;
}

.home-button {
  margin: 10px;
  padding: 5px;
}

.footer-container {
  align-items: center;
  background-color: #282c34;
  color: white;
  display: flex;
  height: 30px;
  justify-content: center;
}

@keyframes alternante {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.2;
    filter: invert(87%) sepia(23%) saturate(3153%) hue-rotate(117deg) brightness(104%) contrast(101%);
  }
  20% {
    opacity: 0.4;
  }
  30% {
    opacity: 0.6;
    filter: invert(38%) sepia(92%) saturate(7433%) hue-rotate(354deg) brightness(95%) contrast(124%);
  }
  40% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
    filter: invert(93%) sepia(30%) saturate(1519%) hue-rotate(360deg) brightness(111%) contrast(101%);
  }
  60% {
    opacity: 0.8;
  }
  70% {
    opacity: 0.6;
    filter: invert(15%) sepia(86%) saturate(5547%) hue-rotate(296deg) brightness(119%) contrast(115%);
  }
  80% {
    opacity: 0.4;
  }
  90% {
    opacity: 0.2;
    filter: invert(100%) sepia(25%) saturate(18%) hue-rotate(88deg) brightness(108%) contrast(102%);
  }
  100% {
    opacity: 0;
  }
}
